import { createGlobalStyle } from 'styled-components';
import tw from 'tailwind.macro';

export default createGlobalStyle`
  html {
    font-family: 'Inter', sans-serif;
  }

  @supports (font-variation-settings: normal) {
      html {
          font-family: 'Inter var', sans-serif;
      }
  }

  body {
    ${tw`m-0 text-indigo-900 bg-white`};
  }

  a {
    ${tw`text-indigo-600 hover:text-indigo-700`};
  }

  p + p {
    ${tw`mt-3`};
  }
`;
